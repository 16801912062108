import type {
  MiniModelPartsFragment,
  Photo360PartsFragment,
  UpdateUrlBasedSoInput,
  VideoPartsFragment,
} from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

export type UrlBasedSOFormValues = {
  name: string;
  url: string;
  collectedBy: string;
  yearCollected: string;
  equipment: string;
};

type UBSO = MiniModelPartsFragment | Photo360PartsFragment | VideoPartsFragment;

export function initialUrlBasedSO(prev?: UBSO): UrlBasedSOFormValues {
  return {
    name: prev?.name ?? '',
    url: prev?.url ?? '',
    collectedBy: prev?.collectedBy ?? '',
    yearCollected: prev?.yearCollected.toString() ?? '',
    equipment: prev?.equipment ?? '',
  };
}

export function urlBasedSOToInput(fv: UrlBasedSOFormValues) {
  return {
    ...fv,
    yearCollected: parseInt(fv.yearCollected),
  } satisfies UpdateUrlBasedSoInput;
}

export const urlBasedSOValidationSchema = yup.object({
  name: yup.string().required(),
  url: yup.string().url().required(),
  collectedBy: yup.string().required(),
  yearCollected: yup.number().integer().positive().required(),
  equipment: yup.string().required(),
});
