import type { PureQueryOptions } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { photo360Parts } from '~/apollo/fragments';
import type {
  UploadOutcropPhoto360PageQuery,
  UploadOutcropPhoto360PageQueryVariables,
} from '~/apollo/generated/schema';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreatePhoto360Form } from '~/components/supportingObject/urlBasedSO/CreatePhoto360Form';
import {
  UrlBasedSOList,
  urlBasedSoListPlacementParts,
} from '~/components/supportingObject/urlBasedSO/UrlBasedSOList';

const UPLOAD_OUTCROP_PHOTO_360_PAGE = gql`
  query UploadOutcropPhoto360Page($id: Int!) {
    outcropList(id: $id) {
      id
      photo360s {
        ...photo360Parts
        placement {
          ...urlBasedSoListPlacementParts
        }
      }
    }
  }

  ${photo360Parts}
  ${urlBasedSoListPlacementParts}
`;

export default function UploadOutcropPhoto360Page() {
  const params = useParams();
  invariant(params.outcropId);
  const outcropId = parseInt(params.outcropId);

  const [isCreating, setIsCreating] = useState(false);

  const queryVars = { id: outcropId };

  const { data, loading } = useQuery<
    UploadOutcropPhoto360PageQuery,
    UploadOutcropPhoto360PageQueryVariables
  >(UPLOAD_OUTCROP_PHOTO_360_PAGE, { variables: queryVars });

  const refetchQueries: [
    PureQueryOptions<UploadOutcropPhoto360PageQueryVariables>,
  ] = [{ query: UPLOAD_OUTCROP_PHOTO_360_PAGE, variables: queryVars }];

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);
  const photo360s = R.sortBy(p => p.name, outcrop?.photo360s ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading>
          <button
            type="button"
            onClick={() => setIsCreating(!isCreating)}
            className="w-full flex justify-between gap-6 items-center"
          >
            <Panel.Title>Create 360 Photo</Panel.Title>
            <ExpandedIcon expanded={isCreating} />
          </button>
        </Panel.Heading>

        {isCreating && (
          <Panel.Body>
            <CreatePhoto360Form
              outcropId={outcropId}
              refetchQueries={refetchQueries}
              onCreateSuccess={() => setIsCreating(false)}
            />
          </Panel.Body>
        )}
      </Panel>

      {!photo360s.length && <p>No 360 Photos created yet.</p>}

      <UrlBasedSOList
        soType="Photo360"
        items={photo360s}
        refetchQueries={refetchQueries}
      />
    </div>
  );
}
