import { Field } from 'formik';
import type {
  MiniModelPartsFragment,
  Photo360PartsFragment,
  VideoPartsFragment,
} from '~/apollo/generated/schema';
import { FormikField } from '~/components/common/FormikField';

type SOType = NonNullable<
  | MiniModelPartsFragment['__typename']
  | Photo360PartsFragment['__typename']
  | VideoPartsFragment['__typename']
>;

export function UrlBasedSOFormFields({ soType }: { soType: SOType }) {
  return (
    <div className="space-y-2">
      <Field name="name" label="Name" component={FormikField} required />
      <Field
        name="url"
        label="URL"
        component={FormikField}
        placeholder="https://sketchfab.com/..."
        helpText={<UrlHelpText soType={soType} />}
        required
      />

      <div className="grid lg:grid-cols-2 gap-6">
        <Field
          name="collectedBy"
          label="Collected by"
          component={FormikField}
          required
        />
        <Field
          name="yearCollected"
          label="Year collected"
          component={FormikField}
          placeholder={new Date().getFullYear()}
          required
        />
      </div>
      <Field
        name="equipment"
        label="Equipment"
        component={FormikField}
        required
      />
    </div>
  );
}

function UrlHelpText({ soType }: { soType: SOType }) {
  switch (soType) {
    case 'MiniModel':
      return (
        <div>
          <p>Sketchfab embed src:</p>
          <p className="text-xs text-muted">
            https://sketchfab.com/models/1550c6e5dc7a478fb928dc0d93802331/embed
          </p>
        </div>
      );

    case 'Photo360':
      return (
        <div>
          <p>Kuula embed src:</p>
          <p className="text-xs text-muted">
            https://kuula.co/share/h5dBD?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1
          </p>
        </div>
      );

    case 'Video':
      return (
        <div>
          <p>Vimeo embed src:</p>
          <p className="text-xs text-muted">
            https://player.vimeo.com/video/447113021?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479
          </p>
        </div>
      );
  }
}
