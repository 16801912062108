import { gql, type PureQueryOptions } from '@apollo/client';
import {
  faBan,
  faCheckCircle,
  faExternalLink,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { Badge, Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import type {
  MiniModelPartsFragment,
  Photo360PartsFragment,
  UrlBasedSoListPlacementPartsFragment,
  VideoPartsFragment,
} from '~/apollo/generated/schema';
import { Panel } from '~/components/common/Panel';
import { Tooltip } from '~/components/common/Tooltip';
import { DeleteUrlBasedSO } from '~/components/supportingObject/urlBasedSO/DeleteUrlBasedSO';
import { UpdateMiniModelForm } from '~/components/supportingObject/urlBasedSO/UpdateMiniModelForm';
import { UpdatePhoto360Form } from '~/components/supportingObject/urlBasedSO/UpdatePhoto360Form';
import { UpdateVideoForm } from '~/components/supportingObject/urlBasedSO/UpdateVideoForm';
import { uploadOutcropSubregionUpdatePlacementsRoute } from '~/paths';

export const urlBasedSoListPlacementParts = gql`
  fragment urlBasedSoListPlacementParts on SupportingObjectPlacement {
    id
    outcropSubregion {
      id
      name
    }
  }
`;

type UrlBasedSO = (
  | MiniModelPartsFragment
  | Photo360PartsFragment
  | VideoPartsFragment
) & {
  placement?: UrlBasedSoListPlacementPartsFragment | null;
};

export function UrlBasedSOList({
  soType,
  items,
  refetchQueries,
}: {
  soType: 'MiniModel' | 'Photo360' | 'Video';
  items: UrlBasedSO[];
  refetchQueries?: PureQueryOptions[];
}) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="space-y-4">
      {items.map(item => (
        <Panel key={item.id}>
          <Panel.Heading>
            <div className="flex justify-between gap-6 items-center w-full">
              <Panel.Title>
                {item.name}{' '}
                <span className="text-muted text-sm">{item.id}</span>
              </Panel.Title>

              <div className="space-x-2">
                {item.placement && (
                  <Badge color="ghost" className="space-x-2">
                    <span className="">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-success"
                      />{' '}
                      Placed
                    </span>

                    {item.placement.outcropSubregion && (
                      <>
                        <span>-</span>
                        <Link
                          to={uploadOutcropSubregionUpdatePlacementsRoute(
                            item.placement.outcropSubregion.id,
                          )}
                          className="link"
                          target="_blank"
                        >
                          {item.placement.outcropSubregion.name}{' '}
                          <FontAwesomeIcon
                            icon={faExternalLink}
                            className="text-[0.5rem] text-muted"
                          />
                        </Link>
                      </>
                    )}
                  </Badge>
                )}
              </div>
            </div>
          </Panel.Heading>

          <Panel.Body>
            {isEditing ? (
              <EditItemForm
                item={item}
                onUpdateSuccess={() => setIsEditing(false)}
              />
            ) : (
              <ItemDetails item={item} />
            )}
          </Panel.Body>

          <Panel.Footer>
            <div className="text-right space-x-1">
              <Button
                type="button"
                onClick={() => setIsEditing(!isEditing)}
                color="ghost"
                size="xs"
                startIcon={
                  <FontAwesomeIcon icon={isEditing ? faBan : faPencil} />
                }
              >
                {isEditing ? 'Cancel' : 'Edit'}
              </Button>

              {!isEditing && (
                <DeleteUrlBasedSO
                  soType={soType}
                  id={item.id}
                  isPlaced={!!item.placement?.id}
                  refetchQueries={refetchQueries}
                >
                  {(deleteItem, isDeleting) => (
                    <Tooltip message="Delete item and any related data (e.g. placements, camera position)">
                      <Button
                        type="button"
                        color="ghost"
                        size="xs"
                        onClick={deleteItem}
                        disabled={isDeleting}
                        loading={isDeleting}
                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  )}
                </DeleteUrlBasedSO>
              )}
            </div>
          </Panel.Footer>
        </Panel>
      ))}
    </div>
  );
}

function EditItemForm({
  item,
  onUpdateSuccess,
}: {
  item: UrlBasedSO;
  onUpdateSuccess: () => void;
}) {
  switch (item.__typename) {
    case 'MiniModel':
      return (
        <UpdateMiniModelForm
          miniModel={item}
          onUpdateSuccess={onUpdateSuccess}
        />
      );
    case 'Photo360':
      return (
        <UpdatePhoto360Form photo360={item} onUpdateSuccess={onUpdateSuccess} />
      );
    case 'Video':
      return <UpdateVideoForm video={item} onUpdateSuccess={onUpdateSuccess} />;
    default:
      console.error(`Can't determine form to show!`, item);
      return null;
  }
}

function RowLabel({ children }: { children: ReactNode }) {
  return (
    <div className="lg:col-span-1 md:col-span-1 text-muted">{children}</div>
  );
}

function RowValue({ children }: { children: ReactNode }) {
  return <div className="lg:col-span-9 md:col-span-5">{children}</div>;
}

function ItemDetails({ item }: { item: UrlBasedSO }) {
  return (
    <div className="grid lg:grid-cols-10 md:grid-cols-6 gap-x-6 gap-y-0">
      <RowLabel>URL</RowLabel>
      <RowValue>
        <div className="break-all">
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            {item.url}{' '}
            <FontAwesomeIcon
              icon={faExternalLink}
              className="text-sm text-slate-300"
            />
          </a>
        </div>
      </RowValue>

      <RowLabel>Collected</RowLabel>
      <RowValue>
        {item.collectedBy}, {item.yearCollected}
      </RowValue>

      <RowLabel>Equipment</RowLabel>
      <RowValue>{item.equipment}</RowValue>
    </div>
  );
}
